import React from 'react'
import {Link} from 'gatsby'
import Layout from 'components/Layout/Layout'
import {Container} from 'reactstrap'
import styled from 'styled-components'
import {media} from "utils/Media"

const CookiePageWrapper = styled.div`
  background-color: ${props => props.theme.colors.brown};
  padding-top: 50px;
  
  @media ${media.lg} {
    overflow: auto;
    height: 100vh;
  }
  
  a {
    color: ${props => props.theme.colors.black};
    transition: all 0.2s ease-in;
    text-decoration: underline;
    
    &:hover {
      color: ${props => props.theme.colors.red};
      text-decoration: none;
    }
  }
  
  .text--bold {
    font-family: ${props => props.theme.font.family.bold};
    font-weight: bold;
  }
`

const CookiePageHeader = styled.div`
  background-color: ${props => props.theme.colors.black};
  text-align: center;
  padding-top: 51px;
  padding-bottom: 3rem;
  
  h1 {
    color: ${props => props.theme.colors.yellow};
  }
`

const CookiePage = () => (

    <Layout slug="cookie-policy" title="Cookie Policy" description="Cookie Policy for To Kill a Mockingbird playing the Gielgud Theatre, London." page="cookie-policy">
        <CookiePageWrapper>
            <CookiePageHeader>
                <h1>Cookie Policy</h1>
            </CookiePageHeader>

            <Container fluid={true} className="py-4">
                <Container>
                    <p>This policy was last updated on 30/10/19. Any future changes to our policy will be reflected here.</p>
                    <h2 className="subtitle">Granting us permission to use cookies</h2>
                    <p>When you first visit the website you will be asked to consent for us to use cookies. You can allow all cookies or refuse some or all. If you change your mind you can click on the Privacy Settings link at the bottom of the website pages to adjust your selection.</p>
                    <h2 className="subtitle">Cookies and how they benefit you:</h2>
                    <p>Our website uses cookies and other tracking technologies, as almost all websites do, to help provide you with the best experience we can. Cookies are small text files that are placed on your computer or mobile phone when you browse websites. Other tracking technologies (referred to together from this point forward as &ldquo;cookies&rdquo;, unless otherwise stated), include HTTP cookies, HTML5 and Flash local storage/flash cookies, web beacons/GIFs, embedded scripts, ETags/cache browsers, and software development kits.</p>
                    <p>To read our Privacy Policy please <Link to="/privacy-policy/">click here</Link>.</p>
                    <p>To Kill A Mockingbird uses cookies for three main purposes:</p>
                    <ul>
                        <li>To ensure the website works, in particular for online booking</li>
                        <li>To monitor website performance and help us make improvements in the future</li>
                        <li>To tailor our marketing, and use tools such as Google AdWords to communicate more effectively through web advertising.</li>
                    </ul>
                    <p>Our cookies help us:</p>
                    <ul>
                        <li>Make our website work as you&rsquo;d expect</li>
                        <li>Save you having to login every time you visit the site</li>
                        <li>Remember your settings during and between visits</li>
                        <li>Improve the speed/security of the site</li>
                        <li>Allow you to share pages with social networks like Facebook</li>
                        <li>Continuously improve our website for you</li>
                        <li>Provide you with advertising relating to this and other shows</li>
                    </ul>
                    <p>We do not use cookies to:</p>
                    <ul>
                        <li>Collect any sensitive information</li>
                    </ul>

                    <p>You can learn more about all the cookies we use below:</p>

                    <h5>Cookies</h5>

                    <div className="table">
                        <div className="table-row">
                            <div className="table-cell">
                                <span className="text--bold">Google Analytics</span>
                            </div>
                            <div className="table-cell">
                                This monitors how people use our site – for example the browser they use and the pages they visit. It’s used to assess our website’s performance and to help us plan improvements.
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="table-cell">
                                <span className="text--bold">DoubleClick / Sizmek</span>
                            </div>
                            <div className="table-cell">
                                <p>You may notice that sometimes after visiting our site you see increased numbers of adverts from us. This is because we pay for these adverts, and the technology to do this is made possible by this cookie. We use these adverts to inform you about this or other shows.</p>
                                
                                <p>The cookies help us understand how well our online adverts are encouraging visitors to view more information about particular events on tokillamockingbird.co.uk. When an advert is displayed on a third-party website, a Sizmek / Doubleclick cookie is placed on the device, providing cookies have not been refused.&nbsp; If that visitor, using the same browser, then visits tokillamockingbird.co.uk, we can see that the advert has been effective. More information about Sizmek&rsquo;s or Doubleclick&rsquo;s own privacy policies and&nbsp;how to opt out can be found here on the <a href="https://www.sizmek.com/privacy-policy/" rel="noreferrer noopener" target="_blank">Sizmek</a> / <a href="https://policies.google.com/privacy" rel="noreferrer noopener" target="_blank">Doubleclick</a> website.</p>
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="table-cell">
                                <span className="text--bold">Facebook / Instagram / Twitter / Snapchat</span>
                            </div>
                            <div className="table-cell">
                                This communicates with Facebook / Instagram / Snapchat / Twitter activity to our website.
                            </div>
                        </div>
                        <div className="table-row">
                            <div className="table-cell">
                                <span className="text--bold">Session</span>
                            </div>
                            <div className="table-cell">
                                This cookie is a transient cookie (it is erased when you close the browser). It acts as a small amount of memory to remember what you’ve done on past pages.
                            </div>
                        </div>
                    </div>

                    <p>If you have any questions about cookies, please contact the Data Protection Manager&nbsp;<a href="mailto:mypersonaldata@soniafriedman.com">mypersonaldata@soniafriedman.com</a></p>

                    <p>
                        <Link to="/">Back to Home</Link>
                    </p>
                </Container>
            </Container>
        </CookiePageWrapper>
    </Layout>
);

export default CookiePage
